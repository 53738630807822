import React from 'react'
import { Link } from 'gatsby'

const Front = () => (
    <>
<div className="cell imageWrapper"><Link to="/etidningen/"><img alt="e-tidningen-latest issue" src="https://mediacdn.prenly.com/api/image/lantmannen.prenly.com/3447/latest-issue"/></Link></div>
  <div className="cell contentWrapper">
    <div className="content">
      <div>
        <h2 className="roboto">Magasinet som går på djupet</h2>
        <p>
        Lantmannen är magasinet för den professionella lantbrukaren som inte lämnar något åt slumpen. I Lantmannen möter du duktiga kollegor som kan inspirera och lyfta dig lite extra. Växtodling, teknik och företagsekonomi är de ämnen som Lantmannen fokuserar på. Där andra skrapar på ytan gräver Lantmannen på djupet.<br/><br/>
Lantmannen kommer med kunnig och välavvägd läsning med fokus på växtodling och ekonomi. Varje år kommer tidningen ut med en populär traktoröversikt som ingen vill missa.
Även om fokus riktas mot svensk produktion görs regelbundna utblickar inte bara i Skandinavien och Europa utan även i övriga världen.
        </p>
      </div>
    </div>
     
    </div>
  </>
)

export default Front
